// Welcome to Code in Framer
// Get Started: https://www.framer.com/developers

import { addPropertyControls, ControlType } from "framer"
import {
    BlogPosting,
    ContactPoint,
    Organization,
    WithContext,
} from "schema-dts"
import { useMemo } from "react"

/**
 * @framerSupportedLayoutWidth auto
 * @framerSupportedLayoutHeight auto
 */
export default function BlogPostingSchema(props) {
    const json: WithContext<BlogPosting> = {
        "@context": "https://schema.org",
        "@type": "BlogPosting",
        "@id": `https://www.krauss-gmbh.com/blog/${props.url}#blogposting`,
        headline: props.title,
        image: props.image?.src ?? undefined,
        mainEntityOfPage: `https://www.krauss-gmbh.com/blog/${props.url}`,
        publisher: {
            "@type": "Organization",
            "@id": "https://www.krauss-gmbh.com/#organization",
        },
        url: `https://www.krauss-gmbh.com/blog/${props.url}`,
        datePublished: props.datePosted,
        dateCreated: props.datePosted,
        dateModified: props.datePosted,
        description: props.description,
        author: [
            {
                "@type": "Person",
                name: props.author,
                url: `https://www.krauss-gmbh.com/author/${props.authorProfile}`,
                "@id": `https://www.krauss-gmbh.com/author/${props.authorProfile}#author`,
            },
            {
                "@type": "Organization",
                "@id": "https://www.krauss-gmbh.com/#organization",
                name: "KRAUSS Neukundengewinnung",
                url: "https://www.krauss-gmbh.com",
            },
        ],
        isPartOf: {
            "@type": "Blog",
            "@id": "https://www.krauss-gmbh.com/blog/#blog",
            name: "KRAUSS Neukundengewinnung Blog",
            publisher: {
                "@type": "Organization",
                "@id": "https://www.krauss-gmbh.com/#organization",
                name: "KRAUSS Neukundengewinnung",
                url: "https://www.krauss-gmbh.com",
            },
        },
    }

    return (
        <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: JSON.stringify(json) }}
        />
    )
}

addPropertyControls(BlogPostingSchema, {
    title: {
        title: "Title",
        type: ControlType.String,
    },
    description: {
        title: "Content",
        type: ControlType.String,
    },
    datePosted: {
        title: "Date Posted",
        type: ControlType.Date,
    },
    image: {
        title: "Image",
        type: ControlType.ResponsiveImage,
    },
    url: {
        title: "Slug",
        type: ControlType.String,
    },
    author: {
        title: "Author",
        type: ControlType.String,
    },
    authorProfile: {
        title: "Author Profile",
        type: ControlType.String,
    },
})
